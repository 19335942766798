import Menu from '@components/menu';
import Select from '@components/select';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { DropdownToggle } from 'components/DropdownToggle';
import { RestrictedInput } from 'components/RestrictedInput';
import { inputRestriction } from 'constants/inputRestrictions';
import { useStore } from 'hooks/useStore';
import { MenuOption } from 'store/fileds/SelectField';

import css from './Rate.module.css';

const Rate = () => {
  const {
    app: { i18n },
    filter: { rate, extraParams },
  } = useStore();

  const [active, setActive] = useState(false);

  if (!extraParams.withRate) return null;

  const handleToggle = () => {
    setActive(!active);
  };

  const handleCurrencyDropdownClick = (menuOption: MenuOption) => {
    rate.currency.setOptionByValue(menuOption.id as number);
    setActive(false);
    rate.totalRateCurrency.reset();
  };

  return (
    <div className={css.rateContainer}>
      <div className={css.currency}>
        <span className={css.currencyLabel}>Мин. ставка,</span>
        <DropdownToggle
          active={active}
          capture={true}
          classNames={{
            wrapper: css.triggerWrapper,
          }}
          dropdown={
            <Menu
              className={css.currencyDropdown}
              options={rate.currency.menuOptions}
              onClick={handleCurrencyDropdownClick}
            />
          }
          onToggle={handleToggle}
        >
          <DropdownToggle.DefaultButton gray active={active} classNames={{ button: css.currencyToggleButton }}>
            <span className={css.buttonText}>{rate.currency.option.label}</span>
          </DropdownToggle.DefaultButton>
        </DropdownToggle>
      </div>

      <div className={css.rateTotal}>
        <RestrictedInput
          clearZeroOnBlur
          classNames={{ wrapper: css.rateInputWrapper }}
          customRestrict={['excessLeadingZeros', 'leadingSymbols']}
          inputRestrictionType={inputRestriction.nonNegativeFloatDecimalLimit}
          maxDecimalPlaces={2}
          maxLength={7}
          placeholder={i18n.app.declensions.transportation.few}
          value={rate.totalRate.value}
          onValueChange={rate.totalRate.setValueFromInput}
        />

        <Select
          className={css.rateTypeSelect}
          classNames={{ select: css.rateTypesInnerSelect }}
          dropdownWidth={{
            min: '100%',
            current: '100%',
          }}
          labelProperty="label"
          options={rate.totalRateCurrency.options}
          usePortal={false}
          value={rate.totalRateCurrency.option}
          onChange={rate.totalRateCurrency.setOption}
        />
      </div>

      {!rate.hasCurrencyPerHourSelected && (
        <div className={css.ratePerKm}>
          <RestrictedInput
            clearZeroOnBlur
            classNames={{ wrapper: css.rateInputWrapper }}
            customRestrict={['excessLeadingZeros', 'leadingSymbols']}
            inputRestrictionType={inputRestriction.nonNegativeFloatDecimalLimit}
            maxDecimalPlaces={2}
            maxLength={7}
            placeholder={i18n.common.kilometer}
            value={rate.ratePerKm.value}
            onValueChange={rate.ratePerKm.setValueFromInput}
          />

          <span className={css.ratePerKmLabel}>{rate.ratePerKmCurrency?.name}</span>
        </div>
      )}
    </div>
  );
};

const connected = observer(Rate);

export { connected as Rate };
