import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { Pagination } from 'components/Pagination';
import { useStore } from 'hooks/useStore';
import { TSubscribedFilter } from 'store/Subscriptions/SubscribedFilter';
import { interpolate } from 'utils/string/format';

import { NotificationInfoPopover } from './NotificationInfoPopover';

import css from './Footer.module.css';

interface IFooter<T> {
  itemsCounter?: 'filter' | 'truck';
  items: T[];
  pageCount: number;
  activePage: number;
  totalItems: number;
  onPageChange: ({ selected }: { selected: number }) => void;
}

const Footer = <T extends TSubscribedFilter>({
  itemsCounter,
  items,
  pageCount,
  activePage,
  totalItems,
  onPageChange,
}: IFooter<T>) => {
  const {
    view,
    app: { locale, i18n },
    subscriptions: { counters },
    ui,
  } = useStore();

  const subscribedFiltersCount = items.filter(item => item.instance?.hasSubscription).length;
  const hasSubscribedFiltersCount = ui.isActiveSavedFiltersTab && Boolean(subscribedFiltersCount);

  const notificationMessage = () => {
    if (counters?.isNotificationsLimitReached) {
      return interpolate(i18n.savedFilters.notificationsInfo.limitReached, {
        max: String(counters?.dailyNotificationsLimit),
      });
    }

    const notificationDesc = ui.isMobile
      ? i18n.savedFilters.notificationsInfo.desc.short
      : i18n.savedFilters.notificationsInfo.desc.full;

    return interpolate(notificationDesc, {
      from: String(counters?.sentNotificationsCount || 0),
      to: String(counters?.dailyNotificationsLimit || 3000),
    });
  };

  return (
    <div className={css.footer}>
      {itemsCounter && Boolean(totalItems) && (
        <span className={css.itemsCount}>
          {hasSubscribedFiltersCount
            ? interpolate(i18n.savedFilters.subscribedFiltersCount, {
                subscribedFiltersCount: subscribedFiltersCount.toString(),
                filtersCount: totalItems.toString(),
              })
            : view.pluralizedCount(totalItems, itemsCounter)}
        </span>
      )}

      {ui.isActiveSavedFiltersTab && (
        <span className={cn(css.notifications, { [css.warning]: counters?.isNotificationsLimitReached })}>
          {notificationMessage()}

          <NotificationInfoPopover />
        </span>
      )}

      {pageCount > 1 && (
        <Pagination
          className={css.pagination}
          initialPage={activePage}
          locale={locale}
          pageCount={pageCount}
          onBlur={() => ui.setShouldSearchByEnter(true)}
          onFocus={() => ui.setShouldSearchByEnter(false)}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};

const connected = observer(Footer);

export { connected as Footer };
