import { ChangeDate } from 'constants/changeDate';
import { Specifications } from 'store/QueryStore/QueryStore';

import { FilterType } from '../types';

const SearchToOld = (searchFilter: FilterType): IOldFilter => {
  return {
    ellipse: mapEllipse(searchFilter),
    routeParams: mapRouteLength(searchFilter),
    ...mapFromGeoPoint(searchFilter),
    ...mapToGeoPoint(searchFilter),
    weight: mapRange(searchFilter, 'weight'),
    volume: mapRange(searchFilter, 'volume'),
    ...mapDates(searchFilter),
    loadingType: mapLoadingType(searchFilter),
    truckType: searchFilter.truckType || 0,
    cargoTypes: searchFilter.cargoTypes || [],
    extraParams: searchFilter.extraParams || 0,
    ...mapRates(searchFilter),
    withDimensions: searchFilter.withDimensions,
    withAuction: searchFilter.withAuction,
    excludeTenders: searchFilter.excludeTenders,
    width: mapRange(searchFilter, 'width'),
    length: mapRange(searchFilter, 'length'),
    height: mapRange(searchFilter, 'height'),
    pallets: searchFilter.pallets || undefined,
    boardList: searchFilter.boardList || [],
    sortingType: searchFilter.sortingType,
    changeDate: mapChangeDate(searchFilter),
    dogruz: mapDogruz(searchFilter),
    ...mapFirms(searchFilter),
    truckId: searchFilter.truckId,
    hideHiddenLoads: true,
    adr: searchFilter.adr,
  };
};

const ELLIPSE_UNITS = [
  {
    key: 1,
    value: '%',
  },
  {
    key: 2,
    value: 'км',
  },
];

const mapChangeDate = (searchFilter: FilterType): ChangeDate => {
  const changeDateMap = {
    all: ChangeDate.any,
    hour: ChangeDate.hours1,
    'three-hours': ChangeDate.hours3,
    today: ChangeDate.today,
    'three-days': ChangeDate.days3,
    week: ChangeDate.week,
  } as { [key: string]: ChangeDate };

  if (typeof searchFilter.changeDate === 'string') {
    return changeDateMap[searchFilter.changeDate];
  }

  return searchFilter.changeDate;
};

const mapEllipse = (searchFilter: FilterType): TOldEllipse => {
  return {
    enabled: Boolean(searchFilter.ellipse),
    maxEnlargment: searchFilter.ellipse?.maxEnlargment ?? 15,
    maxEnlargmentUnit: searchFilter.ellipse?.maxEnlargmentUnit ?? 1,
    minLength: searchFilter.ellipse?.minLength ?? 30,
    minLengthUnit: searchFilter.ellipse?.minLengthUnit ?? 1,
    units: ELLIPSE_UNITS,
  };
};

const mapRouteLength = (searchFilter: FilterType) => {
  return {
    enabled: Boolean(searchFilter.routeLength),
    min: searchFilter.routeLength?.min ?? undefined,
    max: searchFilter.routeLength?.max ?? undefined,
  };
};

const mapFromGeoPoint = (searchFilter: FilterType) => {
  const geoPoint = searchFilter.from;

  const oldGeoPoint: TOldGeoFromPoint = {
    fromRadius: geoPoint?.radius ?? 0,
    exactFromGeos: geoPoint?.exactOnly || false,
  };

  if (!geoPoint) return oldGeoPoint;

  oldGeoPoint.from = ''; // TODO: нужно восстановить из id
  oldGeoPoint.fromGeo = `${geoPoint.type}_${geoPoint.id}`;

  if (geoPoint.listId) {
    oldGeoPoint['fromList'] = {
      id: geoPoint.listId,
      name: 'нужно восстановить название',
      type: geoPoint.listType ?? 0,
    };
  }

  return oldGeoPoint;
};

const mapToGeoPoint = (searchFilter: FilterType) => {
  const geoPoint = searchFilter.to;

  const oldGeoPoint: TOldGeoToPoint = {
    toRadius: geoPoint?.radius ?? 0,
    exactToGeos: geoPoint?.exactOnly || false,
  };

  if (!geoPoint) return oldGeoPoint;

  oldGeoPoint.to = ''; // TODO: нужно восстановить из id
  oldGeoPoint.toGeo = `${geoPoint.type}_${geoPoint.id}`;

  if (geoPoint.listId) {
    oldGeoPoint['toList'] = {
      id: geoPoint.listId,
      name: 'нужно восстановить название',
      type: geoPoint.listType ?? 0,
    };
  }

  return oldGeoPoint;
};

const mapRange = (searchFilter: FilterType, propName: Specifications) => {
  const prop = searchFilter[propName];
  if (!prop) return {};

  return {
    from: prop?.min || null,
    to: prop?.max || null,
  };
};

const mapDates = (searchFilter: FilterType) => {
  return {
    dateOption: searchFilter.dates.dateOption || 'today-plus',
    dateFrom: searchFilter.dates.dateFrom || '1900-01-01',
    ...(searchFilter.dates.dateTo && { dateTo: searchFilter.dates.dateTo }),
  };
};

const mapLoadingType = (searchFilter: FilterType): number => {
  if (searchFilter.loadingType) {
    if (typeof searchFilter.loadingType === 'string') {
      return parseInt(searchFilter.loadingType);
    }

    return searchFilter.loadingType;
  }

  return 0;
};

const mapRates = (searchFilter: FilterType) => {
  if (!searchFilter.rate?.currencyId) return {};

  return {
    currencyId: searchFilter.rate.currencyId,
    rateTotal: searchFilter.rate.rateTotal,
    ratePerKm: searchFilter.rate.ratePerKm,
    ratePerHour: searchFilter.rate.ratePerHour,
  };
};

const mapDogruz = (searchFilter: FilterType) => {
  if (searchFilter.dogruz === true) {
    return 1;
  }

  if (searchFilter.dogruz === false) {
    return 2;
  }

  return 0;
};

const mapFirms = (searchFilter: FilterType) => {
  if (!searchFilter.firm)
    return {
      firmListsExclusiveMode: false,
    };

  const firms = {
    firmListsExclusive: searchFilter?.firm?.firmListsExclusive || [],
    firmListsInclusive: searchFilter?.firm?.firmListsInclusive || [],
    firmListsExclusiveMode: Boolean(searchFilter?.firm?.firmListsExclusive),
    ...(searchFilter.firm.firmGeo && {
      firmGeoIds: `${searchFilter.firm.firmGeo?.type}_${searchFilter.firm.firmGeo?.id}`,
    }),
    firmRating: searchFilter.firm.firmRating,
    firmId: searchFilter.firm.atiId || null,
    firmName: searchFilter.firm.firmName || null,
  };

  return firms;
};

export { SearchToOld };
