import Icon from '@components/icon';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { tabIds } from 'constants/ui';
import { useStore } from 'hooks/useStore';
import { SubscribeButton } from 'layout/Search/SearchResults/SubscribeButton';

import { Tab, TabType } from '../Tab';
import { TabsProps } from '../Tabs';

import css from './Sticky.module.css';

const Sticky = (props: TabsProps) => {
  const { tabsData, onChange, onDisabledClick } = props;

  const {
    app: { i18n, loading, searchLoads, locale },
  } = useStore();

  if (typeof window === 'undefined') return null;

  const handleRefreshClick = () => {
    searchLoads();
  };

  return (
    <div className={cn(css.container, 'ls-tabs')}>
      <div className={css.tabs}>
        {tabsData.map((tab: TabType) => (
          <Tab
            key={tab.id}
            isSticky
            className={cn(css.stickyTab, css[`stickyTab__${locale}`])}
            isActive={tab.id === props.activeTab}
            tab={tab}
            onChange={onChange}
            onDisabledClick={onDisabledClick}
          >
            {tab.label}
          </Tab>
        ))}
      </div>

      {props.activeTab === tabIds.search && (
        <SubscribeButton className={css.subscribeButton} placement="pinned" withDescription={false} />
      )}

      {props.activeTab === tabIds.search && (
        <div className={css.action}>
          <button
            className={cn(css.actionButton, 'glz-button', 'glz-is-primary', 'glz-is-outlined', 'glz-is-small', {
              'glz-is-loading': loading.search,
            })}
            disabled={loading.search}
            type="button"
            onClick={handleRefreshClick}
          >
            <Icon className={css.refreshIcon} color="currentColor" height={12} name="ic_refresh" width={12} />
            <span>{i18n.app.refresh}</span>
          </button>
        </div>
      )}
    </div>
  );
};

const connected = observer(Sticky);

export { connected as Sticky };
