import { makeAutoObservable } from 'mobx';

import { MetricsEvent, events } from 'constants/metrics';
import { TSubscribeButtonProps } from 'layout/Search/SearchResults/SubscribeButton/SubscribeButton';
import { SiteFeaturesIDs } from 'store/SiteFeatures/SiteFeatures';
import { SavedFilter } from 'store/TabsDataStore/TabsDataStore';

import { RootStore } from '../RootStore';
import { PopupIds } from '../UIStore/Popups';
import { NewSavedFilterPopup } from './NewMethods/NewSavedFilterPopup';
import { SavedFilterPopupStore } from './SavedFilterPopupStore';

export const OPENED_FROM_TO_METRIC: { [key in TSubscribeButtonProps['placement']]: MetricsEvent } = {
  searchResults: events.subscribedFromSearchResultsSubscribeButton,
  pinned: events.subscribedFromPinnedSubscribeButton,
};

export type OpenFilterPopupOptions = {
  enableSubscription?: boolean;
  isOpenedBySubscribeAction?: boolean;
  openedFrom?: TSubscribeButtonProps['placement'];
};

export class SavedFiltersStore {
  readonly #root: RootStore;
  popup: SavedFilterPopupStore | NewSavedFilterPopup | null;

  constructor(root: RootStore) {
    this.#root = root;
    this.popup = null;

    makeAutoObservable(this);
  }

  get canSaveFilter() {
    if (!this.#root.profile.data?.isUser || this.#root.profile.data.profile?.passedOnlyFastRegistration) return false;

    return !this.#root.filter.getError({ isVirtuallySubmitted: true });
  }

  openFilterPopup = async (filter?: SavedFilter, options?: OpenFilterPopupOptions) => {
    const useNewFilterMethods = this.#root.siteFeatures.isSiteFeatureEnabled[SiteFeaturesIDs.newSaveFilterMethod];
    this.popup = useNewFilterMethods
      ? new NewSavedFilterPopup(this.#root, options)
      : new SavedFilterPopupStore(this.#root, options);
    this.#root.ui.popups.open(PopupIds.filterPopup);

    if (!this.#root.subscriptions.isCountersInitialized) {
      await this.#root.subscriptions.fetchCounters();
    }

    const isSuccess = await this.popup.validateFilter(filter);

    this.fetchSubscriptionData();

    if (isSuccess) {
      if (options?.enableSubscription) {
        this.popup?.hasSubscription.setData(true);
      }
    }
  };

  fetchSubscriptionData = () => {
    this.popup?.fetchContact();
    this.popup?.fetchSmsParams();
    this.popup?.fetchSubscriptionRate();
    this.popup?.fetchSubscriptionsNotificationsInfo();
  };

  closePopup = () => {
    this.#root.ui.popups.close(PopupIds.filterPopup);
    this.popup?.controller.abort();
    this.popup = null;
  };
}
