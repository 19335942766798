import { getTrucksData } from 'api/tabs';
import { type RootStore } from 'store/RootStore';
import { mapToOldSavedFilter } from 'store/utils';

import { SavedFilter } from '../TabsDataStore';

class TrucksDataStore {
  root: RootStore;
  truckSearchesByPages: { [page: number]: SavedFilter[] };
  selectedFilter: SavedFilter | null;
  totalItems: number;

  constructor(root: RootStore) {
    this.root = root;
    this.truckSearchesByPages = {};
    this.totalItems = 0;
    this.selectedFilter = null;
  }

  get truckSearches() {
    const currentPage = this.root.ui.pagination.pages.byTrucks;
    return this.truckSearchesByPages[currentPage] ?? [];
  }

  setTotalItems = (value: number) => {
    this.totalItems = value;
  };

  setTrucksData = (data: SavedFilter[]) => {
    const currentPage = this.root.ui.pagination.pages.byTrucks;
    this.truckSearchesByPages[currentPage] = data;
  };

  clearTrucksData = () => {
    this.truckSearchesByPages = {};
  };

  fetchTrucksData = async ({ isForced = false }: { isForced?: boolean } = {}) => {
    if (isForced) {
      this.clearTrucksData();
    }

    const currentPage = this.root.ui.pagination.pages.byTrucks;
    if (this.truckSearchesByPages[currentPage]) {
      return;
    }

    try {
      this.root.app.setLoading('tabsData', true);

      const { data } = await getTrucksData({ page: currentPage });

      this.setTrucksData(data.filters.map(mapToOldSavedFilter));
      this.setTotalItems(data.totalNumberOfTrucks);
    } catch (error) {
      console.error(error);
    } finally {
      this.root.app.setLoading('tabsData', false);
    }
  };

  selectFilter = (filter: SavedFilter) => {
    this.selectedFilter = filter;
  };
}

export { TrucksDataStore };
