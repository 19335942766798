import isAfter from 'date-fns/isAfter';

import { SearchToOld } from 'store/FilterStore/Mappers/SearchToOld';
import { snakeToCamelObj } from 'utils/objects';

import { Subscription } from './Subscriptions/types';

const getSubscriptionPromotionEndDate = (
  subscription: Subscription | Pick<IValidateUserFilterResponse, 'promotionSubscriptionEndDate'>,
) => {
  if ((subscription as Subscription).subscriptionPromotionEndDate !== undefined) {
    return (subscription as Subscription).subscriptionPromotionEndDate;
  } else {
    return (subscription as IValidateUserFilterResponse).promotionSubscriptionEndDate;
  }
};

export const hasActivePromotion = (
  subscription?: Subscription | Pick<IValidateUserFilterResponse, 'promotionSubscriptionEndDate'> | null,
): boolean => {
  if (!subscription) return false;

  const subscriptionPromotionEndDate = getSubscriptionPromotionEndDate(subscription);

  if (subscriptionPromotionEndDate) {
    const endDate = new Date(subscriptionPromotionEndDate);
    const hasPromotionSubscription = isAfter(endDate, new Date());

    return hasPromotionSubscription;
  }

  return false;
};

export const mapToOldSavedFilter = (savedFilter: any) => {
  const snakeCasedSavedFilter = snakeToCamelObj(savedFilter);

  return {
    ...snakeCasedSavedFilter,
    filter: SearchToOld(snakeCasedSavedFilter.filter),
    boardsAvailabilityFlag: savedFilter.boardsAvailability,
  };
};
