import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { stikyHeaderHeight } from 'constants/ui';
import { useIntersection } from 'hooks/useIntersection';
import { useStore } from 'hooks/useStore';
import { TTabIds } from 'store/UIStore/UIStore';

import { Static } from './Static/Static';
import { Sticky } from './Sticky';
import { TabType } from './Tab';

import css from './Tabs.module.css';

export interface TabsProps {
  tabsData: TabType[];
  activeTab: TTabIds;
  onChange: (tab: TTabIds) => void;
  onDisabledClick: () => void;
}

const Tabs = (props: TabsProps) => {
  const { ui } = useStore();

  const [ref, observableEntry] = useIntersection({ rootMargin: `-${stikyHeaderHeight}`, threshold: 0.5 });
  const isPageScrolledAway = Boolean(observableEntry) && !observableEntry?.isIntersecting;
  const shouldShowStickyTabs = ui.isSticky && isPageScrolledAway;

  useEffect(() => {
    ui.setIsSticky(isPageScrolledAway);
  }, [isPageScrolledAway, ui]);

  return (
    <div ref={ref} className={css.refContainer}>
      {shouldShowStickyTabs && <Sticky {...props} />}
      <Static {...props} />
    </div>
  );
};

const connected = observer(Tabs);

export { connected as Tabs };
