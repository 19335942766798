import { makeAutoObservable } from 'mobx';

import { geoTypesMap, getGeosByIds } from 'api/geo';
import { uuidRegex } from 'constants/common';
import { extraParams as extraParamsValues } from 'constants/extraParams';
import { TGeoSuggestionValues, geoSuggestionTypes } from 'constants/geo';
import { currenciesRateTypes } from 'constants/payment';
import { TTabIdsQueryMap } from 'store/UIStore/UIStore';
import { LocalityType } from 'types/cargosApp/Geo';
import { cleanEmpty, isEmpty } from 'utils/objects';
import { capitalize } from 'utils/string/format';

import { RootStore } from '../RootStore';

export type QueryParams = {
  tab?: keyof TTabIdsQueryMap;
};

/* Эти параметры на самом деле лежат по хэшем #
  т.е по сути это хэш параметры.
*/
export type QueryData = {
  routeParams?: RouteParams;
  ellipse?: Ellipse;
  from?: string;
  fromGeo?: string;
  fromGeo_tmp?: string;
  fromRadius?: number;
  exactFromGeos?: boolean;
  to?: string;
  toGeo?: string;
  toGeo_tmp?: string;
  toRadius?: number;
  exactToGeos?: boolean;
  toList?: QueryGeoList;
  fromList?: QueryGeoList;
  volume?: MinMax;
  weight?: MinMax;
  length?: MinMax;
  width?: MinMax;
  height?: MinMax;
  dateOption?: DateOptionId;
  dateFrom?: Date;
  dateTo?: Date;
  truckType?: number;
  loadingType?: number;
  cargoTypes?: Array<string | number>;
  extraParams?: number;
  excludeTenders?: boolean;
  adr?: {
    type: 'include';
    ids?: number[];
  };
  // Легаси оплата
  rate?: number;
  rateType?: number;
  currencyTypeId?: number;

  // Оплата
  currencyId?: number;
  rateTotal?: number;
  ratePerKm?: number;
  ratePerHour?: number;
  dogruz?: 1 | 2;
  withDimensions?: boolean;
  pallets?: number;
  sortingType?: number;
  changeDate?: number;
  boardList?: Array<string> | 'onlyPersonal';
  withAuction?: boolean;
  firmName?: string;
  firmId?: string;
  firmRating?: number;
  firmGeo?: string;
  firmGeoIds?: string;
  firmListsExclusiveMode?: boolean;
  firmListsExclusive?: string[];
  firmListsInclusive?: string[];
  hideHiddenLoads?: boolean;
  truckId?: string | null;
};

export type RouteParams = {
  enabled?: boolean;
  max?: number;
  min?: number;
};

type MinMax = {
  from?: number | null;
  to?: number | null;
};

type Ellipse = {
  enabled: boolean;
  maxEnlargment: 15;
  maxEnlargmentUnit: 2;
  minLength: 30;
  minLengthUnit: 2;
};

type QueryGeoList = {
  id: string;
  name: string;
  type: number;
};

const specifications = ['volume', 'weight', 'length', 'width', 'height'] as const;
export type Specifications = typeof specifications[number];

const loadTypes = {
  1: true,
  2: false,
};

const loadTypesReverse: { [key: string]: number } = {
  true: 1,
  false: 2,
};

export enum QueryType {
  byFilter = 'byFilter',
  byLoadId = 'byLoadId',
}

class QueryStore {
  root: RootStore;
  parsedQueryData: QueryData;
  type?: QueryType;
  loadId: string | null = null;

  constructor(root: RootStore) {
    this.root = root;
    this.parsedQueryData = {};

    makeAutoObservable(this);
  }

  get isEmpty() {
    return Object.keys(this.parsedQueryData).length === 0;
  }

  get queryParams(): URLSearchParams {
    if (typeof window === 'undefined') return new URLSearchParams('');

    const urlSearchParams = new URLSearchParams(window.location.search);

    return urlSearchParams;
  }

  get hashParams(): URLSearchParams {
    if (typeof window === 'undefined') return new URLSearchParams('');

    const urlSearchParams = new URLSearchParams(location.hash.substring(2));

    return urlSearchParams;
  }

  get queryData(): Partial<QueryData> {
    const { filter } = this.root;

    const data = {
      ...this.getEllipseQuery(),
      ...this.getRouteLengthQuery(),
      ...this.getGeoQuery('from'),
      ...this.getGeoQuery('to'),
      ...this.getSpecificationsQuery(),
      ...this.getFirmsQuery(),
      pallets: filter.pallets.data,
      dogruz: loadTypesReverse[`${filter.loadType.data}`],
      ...(!filter.loadDate.isDefault && filter.loadDate.requestData),
      truckType: filter.truckType.requestData,
      cargoTypes: filter.cargoType.requestData,
      loadingType: filter.loadingType.requestData,
      ...filter.extraParams.requestData,

      ...(filter.rate.requestData && 'value' in filter.rate.requestData && { rate: filter.rate.requestData.value }),
      ...(filter.rate.requestData && 'type' in filter.rate.requestData && { rateType: filter.rate.requestData.type }),
      ...(filter.rate.requestData &&
        'currencyTypeId' in filter.rate.requestData && { currencyTypeId: filter.rate.requestData.currencyTypeId }),

      ...(filter.rate.requestData &&
        'currencyId' in filter.rate.requestData && { currencyId: filter.rate.requestData.currencyId }),
      ...(filter.rate.requestData &&
        'rateTotal' in filter.rate.requestData && { rateTotal: filter.rate.requestData.rateTotal }),
      ...(filter.rate.requestData &&
        'ratePerKm' in filter.rate.requestData && { ratePerKm: filter.rate.requestData.ratePerKm }),
      ...(filter.rate.requestData &&
        'ratePerHour' in filter.rate.requestData && { ratePerHour: filter.rate.requestData.ratePerHour }),

      changeDate: filter.changeDate.isDefault ? null : filter.changeDate.data,
      sortingType: filter.sortingType.isDefault ? null : filter.sortingType.data,
      boardList: filter.boards.requestData,
      withAuction: filter.boards.withAuction.data,
      truckId: filter.truckIdRequestData,
    };

    return cleanEmpty(data);
  }

  setQuery = () => {
    /* Не триггерит событие hashchange */
    history.replaceState(
      null,
      '',
      `${document.location.pathname}${document.location.search}#?filter=${encodeURI(JSON.stringify(this.queryData))}`,
    );
  };

  clearQuery = () => {
    history.replaceState(null, '', `${document.location.pathname}${document.location.search}`);
  };

  parseQuery = () => {
    const loadId = this.hashParams.get('loadId');

    if (loadId && uuidRegex.test(loadId)) {
      this.setType(QueryType.byLoadId);
      this.setLoadId(loadId);
    } else {
      const filter = this.hashParams.get('filter');
      this.setType(QueryType.byFilter);

      if (filter) {
        try {
          this.setParsedQueryData(cleanEmpty(JSON.parse(filter)));
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  setParsedQueryData = (data: QueryData) => {
    this.parsedQueryData = data;
  };

  setType = (type: QueryType) => {
    this.type = type;
  };

  setLoadId = (loadId: string | null) => {
    this.loadId = loadId;
  };

  get isFromFilter() {
    return this.type === QueryType.byFilter;
  }

  get isFromLoadId() {
    return this.type === QueryType.byLoadId;
  }

  fillFromQuery = async () => {
    this.root.app.setIsDataSetting(true);
    if (!isEmpty(this.parsedQueryData)) {
      this.root.clearFilter();

      this.fillRouteLengthFromQuery();
      this.fillEllipseFromQuery();
      await this.fillGeoFromQuery();
      this.fillSpecifications();
      this.fillDates();
      this.fillRate();
      this.fillAdditionalParams();
      this.fillSorting();
      this.fillBoardList();
      await this.fillFirms();
      this.fillTruckId();
    }
    // Через setTimeout, чтобы сначала отработали реакции, зависящие от того,
    // что мы при заполнении.
    setTimeout(() => {
      this.root.app.setIsDataSetting(false);
    }, 0);
  };

  fillRouteLengthFromQuery = () => {
    const { enabled, min, max } = this.parsedQueryData?.routeParams || {};

    enabled && this.root.filter.routeParams.setIsRouteLengthActive(true);
    this.root.filter.routeParams.routeLength.setData({ min, max });
  };

  fillEllipseFromQuery = () => {
    const { enabled, maxEnlargment, maxEnlargmentUnit, minLength, minLengthUnit } = this.parsedQueryData?.ellipse || {};

    enabled && this.root.filter.routeParams.setIsEllipseActive(true);
    this.root.filter.routeParams.minLength.setValue(minLength || '');
    minLengthUnit && this.root.filter.routeParams.minLengthUnit.setData('value', minLengthUnit);

    this.root.filter.routeParams.maxEnlargment.setValue(maxEnlargment || '');
    maxEnlargmentUnit && this.root.filter.routeParams.maxEnlargmentUnit.setData('value', maxEnlargmentUnit);
  };

  fillGeoFromQuery = async () => {
    const { from, fromGeo, fromGeo_tmp, fromList, fromRadius, to, toGeo, toGeo_tmp, toRadius, toList } =
      this.parsedQueryData;

    const fromLocality = await this.extractGeoFromQuery(from, fromGeo, fromGeo_tmp, fromList);
    const toLocality = await this.extractGeoFromQuery(to, toGeo, toGeo_tmp, toList);

    this.root.filter.from.setLocality(fromLocality);
    this.root.filter.from.setIsExact(Boolean(this.parsedQueryData.exactFromGeos));
    fromRadius && this.root.filter.from.radius.setValue(fromRadius);

    this.root.filter.to.setLocality(toLocality);
    this.root.filter.to.setIsExact(Boolean(this.parsedQueryData.exactToGeos));
    toRadius && this.root.filter.to.radius.setValue(toRadius);
  };

  fillSpecifications = () => {
    specifications.forEach((spec: Specifications) => {
      this.root.filter[spec]?.setData({
        min: this.parsedQueryData[spec]?.from,
        max: this.parsedQueryData[spec]?.to,
      });
    });
  };

  fillDates = () => {
    const { dateTo, dateFrom, dateOption } = this.parsedQueryData;

    if (dateOption) {
      this.root.filter.loadDate.setDateOption(dateOption);
    }

    if (dateOption === 'manual') {
      dateFrom && this.root.filter.loadDate.setFromDate(new Date(new Date(dateFrom).setHours(0, 0, 0, 0)));
      dateTo && this.root.filter.loadDate.setToDate(new Date(new Date(dateTo).setHours(0, 0, 0, 0)));
    }
  };

  fillAdditionalParams = () => {
    const { filter } = this.root;
    const { truckType, loadingType, cargoTypes, extraParams, excludeTenders, dogruz, withDimensions, pallets, adr } =
      this.parsedQueryData;

    truckType && filter.truckType.setTruckTypesFromBitSum(truckType.toString());

    loadingType && filter.loadingType.setLoadingTypesFromBitSum(loadingType.toString());

    cargoTypes?.length && filter.cargoType.setCargoTypes(cargoTypes.map(ct => ct.toString()));

    extraParams && filter.extraParams.setParamsFromBitSum(extraParams.toString());

    excludeTenders && filter.extraParams.addParam(extraParamsValues.tenders);

    adr && filter.extraParams.ADR.setADR(adr);

    dogruz && filter.loadType.setData('value', loadTypes[dogruz]);

    withDimensions && filter.withDimensions.setData(withDimensions);

    pallets && filter.pallets.setValue(pallets);
  };

  fillRate = () => {
    const { filter } = this.root;
    const { currencyId, rateTotal, ratePerKm, ratePerHour } = this.parsedQueryData;

    if (currencyId) {
      filter.rate.currency.setOptionByValue(currencyId);
      rateTotal && filter.rate.totalRate.setValue(rateTotal);
      ratePerKm && filter.rate.ratePerKm.setValue(ratePerKm);

      if (ratePerHour) {
        const currencyPerHour = filter.rate.getCurrencyByRateType(currencyId, currenciesRateTypes.perHour);

        filter.rate.totalRate.setValue(ratePerHour);
        currencyPerHour && filter.rate.totalRateCurrency.setOptionByValue(currencyPerHour.id);
      }
    }
  };

  fillSorting = () => {
    const { sortingType, changeDate } = this.parsedQueryData;

    sortingType && this.root.filter.sortingType.setData('value', sortingType);
    changeDate && this.root.filter.changeDate.setData('value', changeDate);
  };

  fillBoardList = () => {
    const { boardList, withAuction } = this.parsedQueryData;

    withAuction && this.root.filter.boards.withAuction.setData(withAuction);

    if (boardList === 'onlyPersonal') {
      this.root.filter.boards.selectOnlyPersonalBoards();

      return;
    }

    if (boardList?.length) {
      this.root.filter.boards.unSelectAllBoards();
      boardList.forEach((boardId: string) => {
        this.root.filter.boards.selectBoardById(boardId);
      });
    }
  };

  fillFirms = async () => {
    const firms = this.root.filter.firms;
    const {
      firmId,
      firmName,
      firmRating,
      firmGeo,
      firmGeoIds,
      firmListsExclusiveMode,
      firmListsExclusive,
      firmListsInclusive,
    } = this.parsedQueryData;

    if (firmRating) {
      const option = this.root.options.getRatingTypes().find(option => option.value === firmRating);
      option && firms.ratingType.setOption(option);
    }

    if (firmGeoIds) {
      const [type, id] = firmGeoIds.split('_');
      const geoName = firmGeo || (await this.restoreGeoNameFromId(id, parseInt(type, 10) as TGeoSuggestionValues));

      if (type && id) {
        firms.firmGeo.onSuggestionSelected(undefined, {
          suggestion: { id, type: parseInt(type, 10), text: geoName, value: firmGeoIds },
        });
        firms.firmGeo.setValue(geoName);
      }
    }

    if (firmId && firmName) {
      firms.firmName.onSuggestionSelected(undefined, { suggestion: { alias_id: firmId.toString() } });
      firms.firmName.setValue(firmName);
    }

    if (firmListsExclusive?.length || firmListsInclusive?.length) {
      const option = this.root.options.getExclusiveTypes().find(option => option.value === firmListsExclusiveMode);

      if (option) {
        firms.isExclusiveMode.setOption(option);
        if (firmListsExclusiveMode && firmListsExclusive?.length) {
          firms.setSelectedList(firmListsExclusive);
        } else if (firmListsInclusive?.length) {
          firms.setSelectedList(firmListsInclusive);
        }
      }
    }
  };

  fillTruckId = () => {
    const { truckId } = this.parsedQueryData;

    if (truckId) {
      this.root.filter.setTruckId(truckId);
      this.root.filter.setIsTruckInfoIncluded(true);
    }
  };

  getFirmsQuery = () => {
    return this.root.filter.firms.requestUserFilterData;
  };

  getRouteLengthQuery = () => {
    if (!this.root.filter.routeParams.isRouteLengthActive) return null;

    return {
      routeParams: {
        enabled: this.root.filter.routeParams.isRouteLengthActive,
        ...this.root.filter.routeParams.routeLength.requestData,
      },
    };
  };

  getEllipseQuery = () => {
    if (!this.root.filter.routeParams.isEllipseActive) return null;

    return {
      ellipse: {
        enabled: this.root.filter.routeParams.isEllipseActive,
        ...this.root.filter.routeParams.ellipseData,
      },
    };
  };

  getGeoQuery = (geoName: 'from' | 'to') => {
    const geo = this.root.filter[geoName];

    if (!geo.requestData) return null;

    const geoTmp = geo.locality.suggestion?.country_id
      ? `${geo.locality.suggestion?.country_id}_${geo.locality.suggestion?.region_id}`
      : null;

    const queryData = {
      [`${geoName}`]: geo.locality.suggestion?.text,
      [`${geoName}Geo`]: geo.locality.suggestion?.value,
      [`${geoName}Geo_tmp`]: geoTmp,
      [`${geoName}Radius`]: geo.radius.data,
      [`exact${capitalize(geoName)}Geos`]: geo.isExact || null,
      [`${geoName}List`]: geo.list?.id
        ? {
            id: geo.list?.id,
            name: geo.list?.name,
            type: geo.list?.type && 2,
          }
        : null,
    };

    return queryData;
  };

  getSpecificationsQuery = () => {
    const data: { [key in Specifications]?: MinMax } = {};

    specifications.forEach((spec: Specifications) => {
      if (this.root.filter[spec].requestData) {
        data[spec] = {
          from: this.root.filter[spec].requestData?.min || null,
          to: this.root.filter[spec].requestData?.max || null,
        };
      }
    });

    return data;
  };

  restoreListNameFromId = (listId: string) => {
    const geoList = this.root.dictionaries.geoLists.find(list => list.id === listId);

    return geoList?.name;
  };

  restoreGeoNameFromId = async (id: string, geoType: TGeoSuggestionValues) => {
    if (!id) return '';

    switch (geoType) {
      case geoSuggestionTypes.city:
      case geoSuggestionTypes.country:
      case geoSuggestionTypes.region:
        try {
          const { data } = await getGeosByIds([id], geoType);
          const [geo] = data[geoTypesMap[geoType]];

          return geo.name;
        } catch (error) {
          // TODO: позже продумать вариант, как реагируем на проблему
          console.error('error: ', error);
          return '';
        }
      case geoSuggestionTypes.list:
        return this.restoreListNameFromId(id);
      default:
        return '';
    }
  };

  extractGeoFromQuery = async (geoText?: string, geo?: string, geoTmp?: string, list?: QueryGeoList) => {
    const [toCountyId, toRegionId] = geoTmp?.split('_') || [];
    const [toType, toId] = geo?.split('_') || [];

    const geoNameFromQuery = list?.name || geoText || '';
    const geoType = list?.id ? 5 : (parseInt(toType) as TGeoSuggestionValues);

    const geoName = geoNameFromQuery || (await this.restoreGeoNameFromId(toId, geoType));

    const suggestion: LocalityType = {
      text: geoName,
      country_id: parseInt(toCountyId, 10),
      city_name: '',
      attribute: 0,
      id: list?.id || toId,
      type: list?.id ? 5 : parseInt(toType, 10),
      region_id: parseInt(toRegionId, 10),
      value: geo || '',
    };

    return suggestion;
  };
}

export { QueryStore };
