import Skeleton from '@components/skeleton';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';

import { SubscriptionsStatus } from 'components/SubscriptionsStatus';
import { staticTabsId, tabIds } from 'constants/ui';
import { useStore } from 'hooks/useStore';

import { Tab } from '../Tab';
import { TabsProps } from '../Tabs';

import css from './Static.module.css';

const SpecialTab = dynamic(() => import('../Tab/SpecialTab').then(mode => mode.SpecialTab), {
  ssr: false,
  loading: () => <Skeleton height={56} width={214} />,
});

const Static = (props: TabsProps) => {
  const { tabsData, activeTab, onChange, onDisabledClick } = props;

  const {
    followedLoads: { favorites },
  } = useStore();

  const mainTabs = tabsData.slice(0, -1);
  const lastTab = tabsData[tabsData.length - 1];

  return (
    <div>
      <div className={css.container} id={staticTabsId}>
        <div className={css.mainTabs}>
          <div className={css.mainTabs}>
            {mainTabs.map(tab => (
              <Tab
                key={tab.id}
                isActive={tab.id === activeTab}
                tab={tab}
                onChange={onChange}
                onDisabledClick={onDisabledClick}
              >
                {tab.label}

                {tab.id === tabIds.favorites && Boolean(favorites.count) && (
                  <span className={css.counter}>{favorites.count}</span>
                )}

                {tab.id === tabIds.savedFilters && <SubscriptionsStatus />}
              </Tab>
            ))}
          </div>
        </div>

        <SpecialTab
          isSpecial
          isActive={lastTab.id === activeTab}
          tab={lastTab}
          onChange={props.onChange}
          onDisabledClick={onDisabledClick}
        />
      </div>
    </div>
  );
};

const connected = observer(Static);

export { connected as Static };
