import axios from './axios';

function getFiltersTabsData() {
  return axios.get('/gw/filters-bff-api/web/v2/cargos/filters/for-tabs');
}

function getTrucksData({ page }: { page: number }) {
  const take = 15;
  const skip = (page - 1) * take;

  return axios.get(`/gw/loads_filters/web/v1.0/filters/by-trucks?skip=${skip}&take=${take}`);
}

function getTabsData() {
  return axios.get('/api/userfilters/tabs');
}

function deleteSavedFilterRequest(filterId: string) {
  return axios.delete(`/webapi/v1.0/loads/filters/${filterId}`);
}

function deleteLastSearchRequest(filterId: string) {
  return axios.delete(`/webapi/v1.0/loads/search/last-searches/${filterId}`);
}

export { getTabsData, getFiltersTabsData, getTrucksData, deleteSavedFilterRequest, deleteLastSearchRequest };
